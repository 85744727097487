import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Newsletter from "../components/newsletter"
import { FaTag, FaArrowLeft, FaArrowRight } from "react-icons/fa"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const {
    title,
    content,
    uri,
    date,
    databaseId,
    episode,
    podcastTranscription,
    smartcrawlSeo,
  } = post
  const featuredImage = {
    src: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.altText || ``,
  }

  return (
    <Layout>
      <main id="main" className="site-main dft-padding" role="main">
        <article
          id={"post-" + databaseId}
          className="post type-post status-publish format-standard hentry post-template-default single single-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <Seo
            title={smartcrawlSeo?.title}
            description={smartcrawlSeo?.metaDesc}
            ogTitle={smartcrawlSeo?.opengraphTitle}
            ogDescription={smartcrawlSeo?.opengraphDescription}
            twTitle={smartcrawlSeo?.twitterTitle}
            twDescription={smartcrawlSeo?.twitterDescription}
            featuredImage={post.featuredImage?.node?.mediaItemUrl}
            ogImage={smartcrawlSeo?.opengraphImage?.mediaItemUrl}
            twImage={smartcrawlSeo?.twitterImage?.mediaItemUrl}
          />

          <Helmet>
            <script type="application/ld+json">
              {`
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Point of Inquiry",
        "@id": "https://pointofinquiry.org/#website",
        "url": "https://pointofinquiry.org/",
        "logo": {
              "@type": "ImageObject",
                "url": "https://cdn.centerforinquiry.org/wp-content/uploads/sites/30/2019/01/22164519/Point-of-Inquiry-Logo-2018.jpg"
                }
              }     
          `}
            </script>
            <script type="application/ld+json">
              {`
      {
        "@context": "http://schema.org",
        "@type": "PodcastEpisode",
        "partOfSeries": "Point of Inquiry",
        "name": "${post.title}",
        "isAccessibleForFree": true,
        "abstract": "${smartcrawlSeo?.metaDesc}",
        "accessMode": "auditory",
        "audio": {
            "@type": "AudioObject",
            "contentUrl": "${
              "https://directory.libsyn.com/episode/index/id/" +
              episode.libsynId
            }"
            }
        }
          `}
            </script>
          </Helmet>
          {console.log(post.featuredImage?.node?.mediaItemUrl)}
          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.src ? (
            <GatsbyImage
              loading="lazy"
              placeholder="blurred"
              image={featuredImage.src}
              className="article-featured-image d-print-none"
              alt={featuredImage.alt}
              objectFit="cover"
              objectPosition="50% 50%"
              formats={"webp"}
            />
          ) : (
            episode.episodeImage && (
              <div className="featured-outer d-print-none">
                <div
                  className="featured-image blur"
                  style={{
                    backgroundImage: "url(" + episode.episodeImage + ")",
                  }}
                ></div>
                <img
                  data-src={episode.episodeImage}
                  className="ee-image ls-is-cached lazyloaded"
                  src={episode.episodeImage}
                  alt=""
                />
              </div>
            )
          )}

          <div className="readability">
            <header className="entry-header">
              <h1 className="entry-title" itemProp="headline">
                {parse(title)}
              </h1>
              <span style={{ color: "#999" }}>{date}</span>
              <br />
              <div className="entry-meta">
                {post.hosts.nodes.map(host => (
                  <a href={"#" + host.slug}>{host.name}</a>
                ))}
              </div>
              <div className="wp-block-buttons">
                {post.tags.nodes.map(tag => (
                  <div className="wp-block-button small mt-3">
                    <a
                      href={"/tag/" + tag.slug}
                      className="wp-block-button__link has-poi-blue-background-color has-white-color"
                    >
                      <FaTag /> {tag.name}
                    </a>
                  </div>
                ))}
              </div>
            </header>

            {episode.libsynId ? (
              <iframe
                title={title}
                style={{ border: "none", height: "90", width: "100%" }}
                src={
                  "//html5-player.libsyn.com/embed/episode/id/" +
                  episode.libsynId +
                  "/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/14315a/"
                }
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                oallowfullscreen="true"
                msallowfullscreen="true"
              />
            ) : (
              episode.episodeMp3 && (
                <audio controls="controls" preload="auto">
                  <source src={episode.episodeMp3} type="audio/mpeg" />
                </audio>
              )
            )}

            <div className="wp-block-buttons is-content-justification-center mb-3">
              {episode.itunesLink && (
                <div className="wp-block-button">
                  <a
                    href={episode.itunesLink}
                    className="wp-block-button__link has-white-color has-poi-gold-background-color has-text-color has-background"
                  >
                    Listen on iTunes
                  </a>
                </div>
              )}
              {episode.stitcherLink && (
                <div className="wp-block-button">
                  <a
                    href={episode.stitcherLink}
                    className="wp-block-button__link has-white-color has-poi-gold-background-color has-text-color has-background"
                  >
                    Listen on Stitcher
                  </a>
                </div>
              )}
              {episode.spotifyLink && (
                <div className="wp-block-button">
                  <a
                    href={episode.spotifyLink}
                    className="wp-block-button__link has-white-color has-poi-gold-background-color has-text-color has-background"
                  >
                    Listen on Spotify
                  </a>
                </div>
              )}
            </div>

            {!!content && (
              <section className="mb-5" itemProp="articleBody">
                {parse(content)}
              </section>
            )}

            <hr />
            <div className="newsletter-signup mt-5 mb-5">
              <h2 className="h4 center">
                Sign up now for Point of Inquiry updates
              </h2>
              <p className="center">
                New POI episodes and updates sent right to you. It's as easy as
                typing in your email. Your email isn't shared with anyone else.
                Just news and updates.
              </p>
              <Newsletter />
            </div>

            <hr />
            {episode?.episodeLinks && (
              <>
                <h2 className="h4">Links Mentioned in this Episode</h2>
                <ul>
                  {episode.episodeLinks.map((episodeLink, index) => (
                    <li key={index}>
                      <a
                        href={episodeLink.fullUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {episodeLink.linkName}
                      </a>
                    </li>
                  ))}
                </ul>
                <hr />
              </>
            )}

            {podcastTranscription.transcription && (
              <>
                <div className="accordion" id="accordionTranscript">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Show/Hide Transcript
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordionTranscript"
                    >
                      <div className="card-body">
                        {parse(podcastTranscription.transcription)}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </>
            )}

            <div className="wp-block-buttons">
              {post.tags.nodes.map(tag => (
                <div className="wp-block-button small">
                  <a
                    href={"/tag/" + tag.slug}
                    className="wp-block-button__link has-poi-blue-background-color has-white-color"
                  >
                    <FaTag /> {tag.name}
                  </a>
                </div>
              ))}
            </div>
            {post?.hosts?.nodes.map(node => (
              <div className="author-bio">
                <div id={node.slug}>
                  {node.hosts?.authorImage?.mediaItemUrl && (
                    <img
                      src={node.hosts.authorImage.mediaItemUrl}
                      style={{ height: "200", width: "200" }}
                      className="speaker-event-image mr-4 mb-3"
                      alt={node.name}
                    />
                  )}
                  <h2 className="h4">{parse(node.name)}</h2>
                  {node.description && <p>{parse(node.description)}</p>}
                  <div className="wp-block-buttons is-content-justification-center">
                    <div className="wp-block-button small">
                      <Link
                        to={node.uri}
                        className="wp-block-button__link has-white-color has-poi-gold-background-color has-text-color has-background"
                      >
                        See More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </article>

        <nav id="prev-next">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            {next && (
              <Link to={next.uri} rel="next">
                <li className="prev" key={next.id}>
                  <FaArrowLeft /> Previous Episode
                </li>
              </Link>
            )}

            {previous && (
              <Link to={previous.uri} rel="prev">
                <li className="next" key={previous.id}>
                  Next Episode <FaArrowRight />
                </li>
              </Link>
            )}
          </ul>
        </nav>
      </main>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      databaseId
      uri
      content
      title
      date(formatString: "MMMM DD, YYYY")
      episode {
        episodeImage
        episodeMp3
        libsynId
        spotifyLink
        stitcherLink
        itunesLink
        episodeLinks {
          fullUrl
          linkName
        }
      }
      podcastTranscription {
        transcription
      }

      tags {
        nodes {
          name
          slug
        }
      }

      hosts {
        nodes {
          name
          description
          slug
          uri
          hosts {
            authorImage {
              mediaItemUrl
            }
          }
        }
      }

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          mediaItemUrl
        }
      }

      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
      id
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      id
    }
  }
`
